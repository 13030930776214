import { useEffect, useState } from "react";
import { set, useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Button, CircularProgress } from "@mui/material";
import { questionSecDefaultValue } from "../../utils/definitions";
import useYupValidationResolver from "../../utils/yup";
import * as yup from "yup";
import http from "../../utils/axios";
import {
  apiErrorMessage,
  aiResponseToObj,
  formAiPayload,
  paperToRaw,
} from "../../utils/helpers";
import useToast from "../../utils/toast";
import { useUserStore } from "../../stores";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import OcrResultBox from "./OcrResultBox";
import FormInput from "../form/Input";
import QuestionSection from "../../components/exercise/QuestionSection";
import PageTitle from "../common/PageTitle";
import { MonetizationOnOutlined } from "@mui/icons-material";

import GenerateNewButton from "./GenerateNewButton";

const EditExercise = () => {
  const { t } = useTranslation(["exercise", "common"]);
  const navigate = useNavigate();
  const location = useLocation();
  const isGrammar = location.pathname.includes("grammar");
  const isRedo = location.pathname.includes("redo");

  const [isLoading, setIsLoading] = useState(false);
  const [paper, setPaper] = useState({});
  const toast = useToast();
  const userStore = useUserStore();
  const { paperId, attemptId } = useParams();
  // paperId => normal flow
  // attemptId => redo flow
  const [defaultArticle, setDefaultArticle] = useState("");
  const [defaultQa, setDefaultQa] = useState([questionSecDefaultValue]);

  const validationSchema = yup.object({});
  const resolver = useYupValidationResolver(validationSchema);
  const methods = useForm({
    resolver,
    defaultValues: {
      qa: [questionSecDefaultValue],
    },
    values: {
      article: defaultArticle,
      qa: defaultQa,
    },
  });
  const { handleSubmit, control } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "qa",
  });

  const getPaper = async (id) => {
    try {
      const res = await http.get(`/paper/${id}`);
      if (res.error === false) {
        let raw = res?.item?.raw;
        if (!raw) {
          raw = paperToRaw(res.item);
        }
        setPaper({ ...res.item, raw });

        setDefaultArticle(raw);
      } else {
        throw res;
      }
    } catch (error) {
      toast.error(apiErrorMessage(error) || "Unknown Error");
      console.log(error);
    }
  };

  const getAttempt = async () => {
    try {
      const res = await http.get(`/attempt/${attemptId}`);
      // if (res.error === false) {
      //   getPaper(res?.item?.paper?._id);
      // }

      if (res?.item?.article) {
        setDefaultArticle(res?.item?.article);
      }

      if (res?.item?.qa) {
        // check if correct answer is available
        const qa = res.item.qa.map((item) => {
          let answer = item.correct ? item.answer : "";
          return {
            ...item,
            answer: answer,
          };
        });
        setDefaultQa(qa);
      }

      // get paper
      if (res?.item?.paper?._id) getPaper(res?.item?.paper?._id);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const aiPayload = formAiPayload(data);
      const aiRes = isGrammar
        ? await http.post("/ai/grammar", aiPayload)
        : await http.post("/ai/comprehension", aiPayload);

      const attemptPayload = aiResponseToObj({
        res: aiRes.choices[0].message.content,
        article: data.article,
        qa: data.qa,
        user: userStore.user._id,
        paper: paper?._id,
        type: isGrammar ? "grammar" : "comprehension",
      });
      const attemptRes = await http.post("/attempt", attemptPayload);

      if (isGrammar) {
        navigate(`/exercise/grammar/result/${attemptRes.item._id}`);
      } else {
        navigate(`/exercise/comprehension/result/${attemptRes.item._id}`);
      }
    } catch (error) {
      toast.error(apiErrorMessage(error) || "Unknown Error");
    } finally {
      setIsLoading(false);
    }
  };

  const generateNewExercise = async (level, refine = null) => {
    try {
      setIsLoading(true);
      const paperRes = await http.get(`/paper/${paper?._id}`);
      const aiPayload = {
        ...formAiPayload({ article: paper?.raw, qa: [] }),
        grade: paperRes?.item?.grade._id,
        subject: paperRes?.item?.subject._id,
        generate_level: level,
        refine,
      };
      const aiRes = isGrammar
        ? await http.post("/ai/grammar", aiPayload)
        : await http.post("/ai/comprehension", aiPayload);

      if (isGrammar) {
        navigate(`/exercise/grammar/${aiRes?.paper?.id}`);
      } else {
        navigate(`/exercise/comprehension/${aiRes?.paper?.id}`);
      }
    } catch (error) {
      toast.error(apiErrorMessage(error) || "Unknown Error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (attemptId) {
      getAttempt();
    } else {
      getPaper(paperId);
    }
  }, [attemptId, paperId]); // eslint-disable-line

  return (
    <div className="relative">
      {isGrammar ? (
        <PageTitle title={t("header.grammar", { ns: "common" })}></PageTitle>
      ) : (
        <PageTitle title={t("header.reading", { ns: "common" })}></PageTitle>
      )}
      {paper?.raw && (
        <OcrResultBox rawText={paper?.raw} label={t("comp.open-paper")} />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white px-6 py-8 rounded-md mb-8 shadow-md">
          <FormInput
            methods={methods}
            name="article"
            label={t("comp.article")}
            required
          />
          <div className="flex justify-end pt-4">
            {(userStore?.user?.role === "admin" ||
              userStore?.user.role === "teacher") &&
              !isRedo && (
                <GenerateNewButton
                  label={t("comp.generate-new")}
                  generateNewFunc={generateNewExercise}
                  isLoading={isLoading}
                  t={t}
                />
              )}
          </div>
        </div>
        <div className="flex flex-col gap-8">
          {fields.map((item, index) => (
            <QuestionSection
              key={item.id}
              index={index}
              methods={methods}
              remove={remove}
              nameMain="qa"
              name="question"
            />
          ))}
          <div className="flex justify-between">
            <Button
              type="button"
              variant="outlined"
              onClick={() => append(questionSecDefaultValue)}
            >
              {t("comp.add-new")}
            </Button>
            <Button type="submit" variant="contained" disabled={isLoading}>
              <MonetizationOnOutlined />
              &nbsp;{t("comp.submit")}
              {isLoading && <CircularProgress className="absolute" size={20} />}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditExercise;
