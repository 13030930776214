import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { CircularProgress } from "@mui/material";
import { MonetizationOnOutlined } from "@mui/icons-material";

export default function GenerateNewButton({
  generateNewFunc,
  isLoading,
  label,
  t,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [refine, setRefine] = React.useState("");

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (level) => {
    generateNewFunc(level);
    setAnchorEl(null);
  };

  const handleRefine = () => {
    generateNewFunc(2, refine);
    setAnchorEl(null);
  };

  return (
    <>
      <div className="flex w-full justify-end gap-4">
        <Button
          variant="contained"
          id="demo-positioned-button"
          aria-controls={open ? "demo-positioned-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          className="relative"
          onClick={handleClick}
          disabled={isLoading}
        >
          <MonetizationOnOutlined />
          &nbsp;{label}
          <AutorenewIcon className="ml-2" />
          {isLoading && <CircularProgress className="absolute" size={20} />}
        </Button>

        <Button variant="outlined" onClick={() => handleRefine()}>
          <MonetizationOnOutlined />
          &nbsp;{t("comp.refine")}
          <AutorenewIcon className="ml-2" />
          {isLoading && <CircularProgress className="absolute" size={20} />}
        </Button>
        <TextField
          sx={{ flexGrow: 1 }}
          placeholder={t("comp.additional-prompt")}
          value={refine}
          onChange={(e) => setRefine(e.target.value)}
        />
      </div>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        MenuListProps={{
          // menu item width
          sx: { width: anchorEl && anchorEl.offsetWidth },
        }}
      >
        {/*  1 easier, 2 similar, 3 harder  */}
        <MenuItem onClick={() => handleClose(1)}>{t("comp.easier")}</MenuItem>
        <MenuItem onClick={() => handleClose(2)}>{t("comp.similar")}</MenuItem>
        <MenuItem onClick={() => handleClose(3)}>{t("comp.harder")}</MenuItem>
      </Menu>
    </>
  );
}
